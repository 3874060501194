import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "create-a-content-item"
    }}>{`Create a Content Item`}</h1>
    <hr></hr>
    <p>{`To create a new Content Item to be used within your app, follow these step-by-step instructions:`}</p>
    <ol>
      <li parentName="ol">{`Access the Content Management System and navigate to the Content section from the left side menu options. Click on 'Add new Content'.`}</li>
    </ol>
    <p><img alt="CMS Create Content Item" src={require("./images/create_new_content_item.png")} />{` `}</p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`From the list, select which type of content you would like to create:`}</li>
    </ol>
    <p><strong parentName="p">{`To create a Video / Movie Content Item:`}</strong></p>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Provide a name for the Video / Movie Content Item. This is the name the item will appear as while in the Content Management System. Additionallly, when you schedule this item to a screen, this name will represent the item in schedule listings. `}</li>
      <li parentName="ol">{`Upload the video file from your computer. Once uploaded, you can review the file by selecting 'Preview'. Files of a size less than 100 MB are more suited to optimal system performance. `}</li>
      <li parentName="ol">{`Keep 'Aspect Ratio' enabled to have the system automatically detect the duration of the video and set it for you.`}</li>
      <li parentName="ol">{`Disable 'Aspect Ratio' to change the length of the video. Provide the desired length in seconds. If you specify a duration of less than the length of the original file, the item will cut off after the specified time. `}
        <ul parentName="li">
          <li parentName="ul">{`The duration of a video can normally be found by right clicking on the file and selecting 'Properties'. `}</li>
        </ul>
      </li>
      <li parentName="ol">{`Set the desired default volume level by changing the 'Volume' scroll bar. A pop-up may appear asking you if you want to leave the Content Management system. Select 'Leave'. This will reload the page and update the selection. `}</li>
      <li parentName="ol">{`Toggle 'App' in the Publish section.`}</li>
      <li parentName="ol">{`The rest of the fields found on this page can be left alone. `}</li>
      <li parentName="ol">{`Click 'Save' at the bottom of the page. `}</li>
    </ol>
    <p><strong parentName="p">{`To create a Picture / Image Content Item:`}</strong></p>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Provide a name for the Picture / Image Content Item. This is the name the item will appear as while in the Content Management System. `}</li>
      <li parentName="ol">{`Upload the image file from your computer. Once uploaded, you can review the file by selecting 'Preview'. Guidelines for uploaded image file:`}
        <ul parentName="li">
          <li parentName="ul">{`Image resolution: 1280 x 720`}</li>
          <li parentName="ul">{`Image quality: 72 dpi`}</li>
          <li parentName="ul">{`Image depth : 24-bit-color`}</li>
        </ul>
      </li>
      <li parentName="ol">{`Keep 'Aspect Ratio' enabled. `}</li>
      <li parentName="ol">{`Toggle 'App' in the Publish section.`}</li>
      <li parentName="ol">{`The rest of the fields found on this page can be left alone. `}</li>
      <li parentName="ol">{`Click 'Save' at the bottom of the page. `}</li>
    </ol>
    <p><strong parentName="p">{`To create a Powerpoint PTT Content Item:`}</strong></p>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Provide a name for the Powerpoint PTT Content Item. This is the name the item will appear as while in the Content Management System. `}</li>
      <li parentName="ol">{`Upload the PowerPoint file from your computer. Once uploaded, you can review the file by selecting 'Preview'. The system runs in a fully automated and unattended fashion. The presentation will not be prompted by a mouse click to move on to the next slide. As such, your PowerPoint presentation must be set to advance each slide automatically. `}
        <ul parentName="li">
          <li parentName="ul">{`For PowerPoint 2000/2002/2003, go to the “Slide Show” menu, then select “Slide Transition”. Under the “Advance slide” heading, check the “Automatically after” box, and specify how long each slide should be displayed for. Click “Apply to All Slides” if this is appropriate for all slides, or specify the timing individually for each slide.`}</li>
          <li parentName="ul">{`For PowerPoint 2007, click the “Animations” tab, then check the “Automatically after” box, and specify how long this slide should be displayed for. Repeat for each slide in the presentation. Ensure you save the file as a Powerpoint 97-2003 (.PPT) file.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`Keep 'Duration' and 'Aspect Ratio' enabled. `}</li>
      <li parentName="ol">{`Toggle 'App' in the Publish section.`}</li>
      <li parentName="ol">{`The rest of the fields found on this page can be left alone. `}</li>
      <li parentName="ol">{`Click 'Save' at the bottom of the page. `}</li>
    </ol>
    <p><strong parentName="p">{`To create a RSS News Feed Content Item:`}</strong></p>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Provide a name for the RSS News Feed Content Item. This is the name the item will appear as while in the Content Management System. `}</li>
      <li parentName="ol">{`Enter in the RSS Feed's URL in the 'News Feed' field. `}</li>
      <li parentName="ol">{`Toggle 'App' in the Publish section. `}</li>
      <li parentName="ol">{`The rest of the fields found on this page can be left alone.`}</li>
      <li parentName="ol">{`Click 'Save' at the bottom of the page. `}</li>
    </ol>
    <p><strong parentName="p">{`To create a Live Web Page Content Item:`}</strong></p>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Provide a name for the Live Web Page Content Item. This is the name the item will appear as while in the Content Mangement System. `}</li>
      <li parentName="ol">{`Provide the URL for the web page in the 'Web URL' field. `}</li>
      <li parentName="ol">{`Toggle 'App' in the Publish section. `}</li>
      <li parentName="ol">{`The rest of the fields found on this page can be left alone. `}</li>
      <li parentName="ol">{`Click 'Save' at the bottom of the page. `}</li>
    </ol>
    <p><strong parentName="p">{`To create a PDF Document Content Item:`}</strong></p>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Provide a name for the PDF Document Content Item. This is the name the item will appear as while in the Content Management System. `}</li>
      <li parentName="ol">{`Toggle 'App' in the Publish section. `}</li>
      <li parentName="ol">{`The rest of the fields found on this page can be left alone. `}</li>
      <li parentName="ol">{`Click 'Save' at the bottom of the page. `}</li>
    </ol>
    <p><strong parentName="p">{`To create a Plain Text Content Item:`}</strong></p>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Provide a name for the Plain Text Content Item. This is the name the item will appear as while in the Content Management System. `}</li>
      <li parentName="ol">{`Write the message in plaint text in the 'Message' field. `}</li>
      <li parentName="ol">{`Pick a text color from within the box to the right of 'Text Style'. You can pick from color palette provided. `}</li>
      <li parentName="ol">{`Select the font family from the dropdown menu. `}</li>
      <li parentName="ol">{`Select the font style from the dropdown menu. `}</li>
      <li parentName="ol">{`Set the font size in the 'pt' field. `}</li>
      <li parentName="ol">{`Toggle 'App' in the Publish section.`}</li>
      <li parentName="ol">{`The rest of the fields found on this page can be left alone. `}</li>
      <li parentName="ol">{`Click 'Save' at the bottom of the page. `}</li>
    </ol>
    <p>{`Once the Content Item has been created, you will be directed to the Edit Schedule page. Depending on your preferences, you can schedule the item to only display at certain times within your app. `}<em parentName="p">{`If you don't wish to provide a schedule, you can click 'Cancel'. This action will create the Content Item without a set schedule.`}</em></p>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Provide a date range in the From and Until calendars. This details the start date to display the content item and when the display should end. `}</li>
      <li parentName="ol">{`Detail when to display the item by selecting an option under Show:`}
        <ul parentName="li">
          <li parentName="ul">{`Always will constantly display the item during the set time frame in the From and Until fields.`}</li>
          <li parentName="ul">{`Every Day will display the item during certain times. Selecting this and specifying a start and end time will clause the item to be shown during those times e.g. to show an item only between 9 AM and noon.`}</li>
          <li parentName="ul">{`By Day will let you pick which days during the set frame in the From and Until fields to display the item. For example, if set. to Monday, Wednesday and Friday, the item will only display in your app on those specific days. `}</li>
        </ul>
      </li>
      <li parentName="ol">{`Leave the dropdown menu set to 'Regular Item'.`}</li>
      <li parentName="ol">{`Select the Channels you want this content item related to.  `}</li>
      <li parentName="ol">{`When directed back to the Edit Content your configured schedules will display at the bottom of the page. `}</li>
      <li parentName="ol">{`Click 'Save' at the bottom of the page. `}</li>
    </ol>
    <p>{`Related Training Video: `}<a parentName="p" {...{
        "href": "https://appdocs.sol.us/trainings-creating-content"
      }}>{`Creating Content Training Video`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      